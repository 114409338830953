import React, { Component } from 'react'
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

// https://stackoverflow.com/questions/43441856/how-to-scroll-to-an-element
const utilizeScroll = () => {
    const htmlElRef = React.createRef()
    const executeScroll = () => {

        const scrollPos = htmlElRef.current.offsetTop - 100;

        /*
        const scrollSteps = 1000;
        const startPos = window.scrollY;
        let delta = (scrollPos - startPos) / scrollSteps;
        for( var i = 0; i < scrollSteps; i++) {
            window.scrollTo(0, startPos + (i * delta) );
        }*/

        window.scrollTo(0, scrollPos);
    }
  
    return {executeScroll, htmlElRef}
  }

export class ProjectPageContent extends Component {
    constructor(){
        super();
        this.elScroll = utilizeScroll();
    }

    // State for the app - Assume we would fetch this from backend and such
    state = {
        projectContent: '',
    }

    componentDidMount() {
        // this.elScroll.executeScroll();

        if(this.state.projectContent) {
            return;
        }

        // Fetch article content from backend
        // Be careful - rendering code needs to be able to handle nulls
        fetch(`${process.env.PUBLIC_URL}/projects/${this.props.projectId}/index.md`)
        .then(result => { // Act on the promise
            return result.text(); // Grab the data (Not Json here)
        }).then(text => { // Update the state 
            this.setState({
            projectContent: text
            })
        });

        // // Fetch script for live content
        // // Be careful - rendering code needs to be able to handle nulls
        // fetch(`${process.env.REACT_APP_BACKEND_URL}/project/${this.props.projectId}/script`)
        // .then(result => { // Act on the promise
        //     return result.text(); // Grab the data (Not Json here)
        // }).then(text => { // Update the state 
        //     this.setState({
        //     projectScript: text
        //     })
        // });
    }
    
    render() {
        const {projectContent} = this.state;

        const linkTransform = uri => uri ? uri.startsWith("http") ? uri : `${process.env.PUBLIC_URL}/projects/${this.props.projectId}/files/${uri}` : "";
        const imgTransform = uri => uri ? uri.startsWith("http") ? uri : `${process.env.PUBLIC_URL}/projects/${this.props.projectId}/images/${uri}` : "";

        return (
            <div ref={this.elScroll.htmlElRef}>
              <ReactMarkdown
                children={projectContent}
                transformLinkUri={linkTransform}
                transformImageUri={imgTransform}
                className="react-markdown-block"
                />
            </div>
        )
    }
}

// Define prop types for this class
ProjectPageContent.propTypes = {
    projectId: PropTypes.number.isRequired,
  }

export default ProjectPageContent

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css';
import Header from './components/layout/Header'
import Projects from './components/Projects'
// import About from './pages/About'

class App extends Component {
  // State for the app - Assume we would fetch this from backend and such
  state = {
    projects: [
    ],
    currentProjectIndex: 0,
    lastProjectIndex: 0,
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onkeydown, false);

    // Fetch projects data
    // Be careful - rendering code needs to be able to handle nulls
    fetch(`${process.env.PUBLIC_URL}/projects/projects.json`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }
    )
    .then(results => { // Act on the promise
      return results.json(); // Grab the json
    }).then(data => { // Process the json
      this.setState({
        projects: data,
      })
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onkeydown, false);
  }

  // Event handlers for switching projects
  viewPreviousProject = () => {
    if( this.state.currentProjectIndex > 0 ) {
      this.setState((state) => {
        return {
          lastProjectIndex: state.currentProjectIndex,
          currentProjectIndex: state.currentProjectIndex - 1
        }
      });
    }
  }

  viewNextProject = () => {
    if( this.state.currentProjectIndex < this.state.projects.length - 1 ) {
      this.setState((state) => {
        return {
          lastProjectIndex: this.state.currentProjectIndex,
          currentProjectIndex: this.state.currentProjectIndex + 1
        }
      });
    }
  }

  selectProject = (index) => {
    this.setState({
      currentProjectIndex: index,
      lastProjectIndex: index,
    });
  }

  // Handle keyboard events
  onkeydown = (e) => {
      switch (e.key) {
          case 'ArrowUp':
          case 'ArrowLeft':
            this.viewPreviousProject();
            break;
          case 'ArrowDown':
          case 'ArrowRight':
            this.viewNextProject();
            break;
          default:
            break;
      }
  }

  render() {
    // TODO: Render a header with a nice! graphic
    return (
      <Router>
        <div className="App" key="2e5732b1-371c-4c88-b879-b660451f6327">
            <Header />
            <Projects
              key="4f04e429-2244-467a-89d7-3e43290a06b3"
              projects={this.state.projects}
              currentProjectIndex={this.state.currentProjectIndex}
              lastProjectIndex={this.state.lastProjectIndex}
              viewPreviousProject={this.viewPreviousProject}
              viewNextProject={this.viewNextProject}
              selectProject={this.selectProject}
              />
              {/* <Routes>
                <Route exact path="/" render={props => (
                     
                )} />
                // {<Route exact path="/about" component={About} /> }
              </Routes> */}
        </div>
      </Router>
    );
  }
}

export default App;

import React from 'react';
import './Header.css'

function Header() {
    return (
        <header className='PageHeader'>
            {/* <h1 style={{float:"left", verticalAlign:"middle"}}><a href="http://gfrancisdev.co.uk">gfrancisdev.co.uk</a></h1>
            <a target="_blank" rel="noopener noreferrer" href="http://uk.linkedin.com/in/gfrancisdev" style={{textDecoration:"none"}}><img src="linkedinlogo.png" height="64" alt="LinkedIn" style={{verticalAlign:"middle", float: 'right', border:0}} /></a>
            <a target="_blank" rel="noopener noreferrer" href="http://github.com/geefr" style={{textDecoration:"none"}}><img src="GitHub-Mark-Light-120px-plus.png" height="64" alt="Github" style={{verticalAlign:"middle", float: 'right', border:0}} /></a> */}
        </header>
    )
    // <Link to="/">Home</Link> | <Link to="/about">about</Link>
}

export default Header;

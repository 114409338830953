import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import ProjectPage from './ProjectPage';
import PropTypes from 'prop-types';
import './Projects.css'

class Projects extends Component {
    getProjectPageClassName = (index) => {
        if( index === this.props.currentProjectIndex ) {
            return "ProjectPage";
        } else {
            return "ProjectPage-Dim"
        }
    }

    render() {
        if( this.props.projects.length === 0 ) return (null);

        // Render the current project on the page
        // Plus a left/right pagination buttons
        /*
        const index = this.props.currentProjectIndex;
        const currentProject = this.props.projects[index];
        const prevProject = index > 0 ? this.props.projects[index - 1] : null;
        const nextProject = index < this.props.projects.length ? this.props.projects[index + 1] : null;
        */

        // Loop through projects and output jsx for each
        return(
            this.props.projects.map((item, index) => 
                <div className='ProjectsList' key={`ffdfb38f-54f4-4773-b118-e602688e2430-${index}`}>
                    <CSSTransition key={index}
                    classNames={this.getProjectPageClassName(index)}
                    timeout={500}
                    >
                    <ProjectPage 
                        key={item.id}
                        project={item}
                        projectIndex={index}
                        expanded={this.props.currentProjectIndex === index ? true : false}
                        selectProject={this.props.selectProject}
                        style={this.getProjectPageClassName(index)}/>
                    </CSSTransition>
                </div>
            )
/*
            
            <div>
                {prevProject != null ? (
                    <div>
                        <PageButton 
                            key={prevProject.id} 
                            project={prevProject} 
                            isNext={false} 
                            viewPreviousProject={this.props.viewPreviousProject} 
                            viewNextProject={this.props.viewNextProject}/>
                    </div>
                ) : (null) }
                
                <div>
                    <TransitionGroup>
                        <CSSTransition key={index}
                        classNames={this.getProjectPageClassName()}
                        timeout={500}
                        >
                        <ProjectPage 
                            key={currentProject.id}
                            project={currentProject}
                            style={this.getProjectPageClassName()}/>
                        </CSSTransition>
                    </TransitionGroup>
                </div>

                {nextProject != null ? (
                    <div>
                        <PageButton 
                            key={nextProject.id} 
                            project={nextProject} 
                            isNext={true} 
                            viewPreviousProject={this.props.viewPreviousProject} 
                            viewNextProject={this.props.viewNextProject}/>
                    </div>
                ) : (null) }
            </div>*/
        );
    }
}

// Define prop types for this class
Projects.propTypes = {
    projects: PropTypes.array.isRequired,
    currentProjectIndex: PropTypes.number.isRequired,
    lastProjectIndex: PropTypes.number.isRequired,
    viewPreviousProject: PropTypes.func.isRequired,
    viewNextProject: PropTypes.func.isRequired,
    selectProject: PropTypes.func.isRequired,
}

export default Projects;
